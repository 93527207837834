<template>
  <!-- Show patient picture -->
  <div
    v-viewer="options"
    class="previewHeight"
  >
    <b-row v-if="type === 'MR'">
      <template v-for="(thumbnail) in picture">
        <b-img
          :key="thumbnail"
          v-bind="mrImgConfig"
          :src="thumbnail"
          fluid
          thumbnail
          alt=" "
          viewer
        >
          />
        </b-img>
      </template>
    </b-row>
    <!-- <b-row v-if="type === 'EMR'">
      <template v-if="">

      </template>
    </b-row> -->
    <b-row v-if="type === 'IPD'">
      <template v-for="(thumbnail) in picture">
        <b-img
          :key="thumbnail"
          v-bind="imgConfig"
          :src="thumbnail"
          fluid
          thumbnail
          alt=" "
          viewer
        >
          />
        </b-img>
      </template>
    </b-row>
    <b-row v-if="type === 'Other'">
      <template v-for="(thumbnail) in picture">
        <b-img
          :key="thumbnail"
          v-bind="imgConfig"
          :src="thumbnail"
          fluid
          thumbnail
          alt=" "
          viewer
        >
          />
        </b-img>
      </template>
    </b-row>
    <b-pagination
      v-if="type === 'IPD'"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="left"
      class="mt-2"
    >
      <template
        #first-text
      >
        <span class="text-success">First</span>
      </template>
      <template #prev-text>
        <span class="text-danger">Prev</span>
      </template>
      <template #next-text>
        <span class="text-warning">Next</span>
      </template>
      <template #last-text>
        <span class="text-info">Last</span>
      </template>
      <template #ellipsis-text>
        <div>
          <b-spinner
            small
            type="grow"
          />
          <b-spinner
            small
            type="grow"
          />
        </div>
      </template>
      <template #page="{ page, active }">
        <b v-if="active">{{ page }}</b>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
    <div v-if="type === 'EMR'">
      <b-row>
        <b-col
          v-if="emrLocation == 'general'"
          cols="12"
        >
          <general
            :txn="txn"
            :hn="hn"
          />
        </b-col>
        <b-col
          v-else-if="emrLocation == 'wellBaby'"
          cols="12"
        >
          <wellBaby
            :txn="txn"
            :hn="hn"
          />
        </b-col>
        <b-col
          v-else-if="emrLocation == 'checkUp'"
          cols="12"
        >
          <checkUp
            :txn="txn"
            :hn="hn"
          />
        </b-col>
        <b-col
          v-else-if="emrLocation == 'obg'"
          cols="12"
        >
          <obg
            :txn="txn"
            :hn="hn"
          />
        </b-col>
      </b-row>
    </div>
  </div>
  <!-- Show patient picture -->
</template>

<script>
import VueViewer from 'v-viewer'
import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import { BPagination, BSpinner } from 'bootstrap-vue'
import general from '@/components/record-viewer/general.vue'
import checkUp from '@/components/record-viewer/checkup.vue'
import obg from '@/components/record-viewer/ob.vue'
import wellBaby from '@/components/record-viewer/wellBaby.vue'
import store from '../../../../store'

Vue.use(VueViewer)

export default {
  components: {
    BPagination,
    BSpinner,
    general,
    checkUp,
    obg,
    wellBaby,
  },
  props: {
    date: {
      type: String,
      default: () => '',
    },
    ipdDate: {
      type: undefined,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    pic: {
      type: String,
      default: () => '',
    },
    picGroup: {
      type: Array,
      default: () => '',
    },
    group: {
      type: String,
      default: () => '',
    },
    hn: {
      type: undefined,
      default: () => '',
    },
    txn: {
      type: undefined,
      default: () => '',
    },
  },
  data() {
    return {
      name: '',
      emrLocation: '',
      windowTop: 0,
      currentPage: 1,
      perPage: 21,
      rows: 38,
      offSet: 0,
      amountDocument: '',
      picture: [],
      imgConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 220,
        height: 270,
        class: 'ml-1 mb-1',
        center: false,
      },
      mrImgConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 350,
        height: 350,
        class: 'ml-1 mb-1',
        center: true,
      },
      otherSelected: [],
      mrSelected: [],
      ipdSelected: [],
      dataArray: [],
      generalList: ['OP7ME1', 'OP7ME2', 'MED13A', 'MRC'],
      checkupList: ['CHECKUP'],
      wellBabyList: ['WELBABY'],
      obList: ['OBG', 'OP7SUR'],
      options: {
        toolbar: true,
      },
      selectedItem: {
        MRs: [{
          imageName: '',
          location: '',
          group: 'mr',
          // type: '',
          date: '',
        }],
        EMRs: [{
          txn: '',
          location: '',
          group: 'emr',
          // type: '',
          date: '',
        }],
        Others: [{
          imageName: '',
          // location: '',
          group: 'other',
          type: '',
          date: '',
        }],
        IPDs: [{
          imageBase64: '',
          // location: '',
          group: 'ipd',
          date: '',
          // type: '',
        }],
      },
    }
  },
  async created() {
    this.token = localStorage.getItem('tokenFetchImage')
    if (this.type !== '') {
      if (this.type === 'MR' || this.type === 'Other') {
        await this.getPic()
        this.dataArray = [this.date, this.group]
      } else if (this.type === 'IPD') {
        await this.getAmountIpd()
        await this.getIpdPic()
        this.dataArray = [this.date, this.group]
      } else if (this.type === 'EMR') {
        if (this.checkupList.includes(this.group)) {
          this.emrLocation = 'checkUp'
        } else if (this.wellBabyList.includes(this.group)) {
          this.emrLocation = 'wellBaby'
        } else if (this.obList.includes(this.group)) {
          this.emrLocation = 'obg'
        } else {
          this.emrLocation = 'general'
        }
      }
    }
  },
  async mounted() {
    let fromGetter = ''
    if (this.type === 'OTHER') {
      fromGetter = store.getters['hieStateImage/setOtherSelect'](this.dataArray)
    } else if (this.type === 'IPD') {
      fromGetter = store.getters['hieStateImage/setIpdSelect'](this.dataArray)
    }
    if (fromGetter && this.type === 'OTHER') {
      this.otherSelected = fromGetter
    } else if (fromGetter && this.type === 'IPD') {
      this.ipdSelected = fromGetter
    }
  },
  methods: {
    // หาจำนวนรูปIPD สำหรับคำนวณ
    getAmountIpd() {
      const month = +this.date.substring(6, 7)
      const year = parseInt(this.date.substring(0, 4), 10)
      this.$http({
        url: 'http://192.168.100.147/api/Profile/ClinicIPD',
        method: 'POST',
        data: {
          hn: this.hn,
          Year: year,
          Month: month,
        },
      }).then(({ data }) => {
        this.rows = data[0].amountDocument
      })
    },
    // หารูปของ Mr และ Other
    async getPic() {
      if (this.type === 'MR') {
        this.getPicData('OPD', this.pic)
      } else {
        for (let index = 0; index < this.picGroup.length; index += 1) {
          this.getPicData('Grp', this.picGroup[index].nameImage)
        }
      }
    },
    getImageName(index) {
      const param = [this.date, index]
      return store.getters['hieDetail/setHieTimeLine'](param)
    },
    async getIpdPic() {
      await this.$http({
        url: 'api/hospital-information-exchange/getIPDImagesByDate',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          hn: this.hn,
          date: this.date,
          rid: this.$route.params.rid,
        },
      }).then(({ data }) => {
        data.forEach(element => {
          this.picture.push(`data:image/jpeg;base64, ${element}`)
        })
      })
    },

    async getPicData(picType, picImage) {
      await this.$http({
        url: 'http://192.168.100.177/api/Profile/GetImage',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          Grp: this.group,
          IMage: picImage,
          Type: picType,
          hn: this.hn,
        },
      }).then(({ data }) => {
        this.picture.push(`data:image/jpeg;base64, ${data.imageBase64}`)
      })
    },
  },
}
</script>

<style>
.yourContainer{
  width: auto !important;
  height: 50px !important;
  margin: 0 auto 1em auto; /*Center the image*/
}
/* .previewHeight{
  height: 500px;
  overflow: auto;
} */
</style>

<style lang='scss'>
</style>
