<template>
  <div>
    <b-row>
      <!-- Section 1 -->
      <b-col
        xl="3"
        cols="12"
        class="px-custom-r"
      >
        <b-row>
          <b-col>
            <!-- Show patient info -->
            <patientDetail
              class="container"
              :hn="hn"
            />
            <manual>
            <!-- Show patient info -->
            </manual>
          </b-col>
        </b-row>
      </b-col>
      <!-- Section 1 -->
      <!-- Section 2 -->
      <b-col
        xl="9"
        cols="12"
        class="px-custom-l"
      >
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <b-card class="container px-0 card-padding">
            <h5 class="text-primary">
              <feather-icon
                icon="FolderIcon"
                class="mr-75"
              />บันทึกเวชระเบียน</h5>
            <hr>
            <b-row>
              <b-col
                xl="3"
                cols="3"
                class="picHeight"
              >
                <!-- <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="ประเภทเอกสาร"
                  variant="primary"
                  size="sm"
                >
                  <b-dropdown-item @click="filterResult(1)">
                    EMR
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterResult(2)">
                    MR
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterResult(3)">
                    IPD
                  </b-dropdown-item>
                  <b-dropdown-item @click="filterResult(4)">
                    เอกสารอื่น
                  </b-dropdown-item>
                </b-dropdown> -->
                <br>
                <br>
                <!-- Show patient timeline -->
                <app-timeline>
                  <app-timeline-item
                    v-for="(item, finalDataIndex) in items"
                    :key="finalDataIndex"
                    class="container"
                    variant="success"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    >
                      <h5>{{ item.date }}</h5>
                    </div>
                    <!-- Show OPD/IPD/Document -->
                    <!-- OPD/MR -->
                    <div
                      v-for="(mrData, mrIndex) in item.mr"
                      :key="item.date + mrIndex + 'mr'"
                      class="justify-content-between"
                    >
                      <label
                        class="pointer"
                        @click="getMr_Image(mrData, item.date)"
                      >
                        <b-badge
                          pill
                          variant="light-primary"
                        >
                          MR
                        </b-badge>
                        {{ mrData.location }}
                      </label>
                    </div>
                    <!-- OPD/EMR -->
                    <div
                      v-for="(emrData, emrIndex) in item.emr"
                      :key="index + emrIndex + 'emr'"
                      class="justify-content-between"
                    >
                      <label
                        v-if="emrData.location !== ''"
                        class="pointer"
                        @click="getEmr(emrData, item.date)"
                      >
                        <b-badge
                          pill
                          href="#"
                          variant="light-success"
                        >
                          EMR
                        </b-badge>
                        {{ emrData.location }}
                      </label>
                    </div>
                    <!-- IPD -->
                    <div v-if="item.ipd.length > 0">
                      <label
                        class="pointer"
                        @click="getIpd(item.date)"
                      >
                        <b-badge
                          pill
                          variant="light-success"
                        >
                          IPD
                        </b-badge>
                      </label>
                    </div>
                    <!-- Other -->
                    <div
                      v-for="(otherData, otherIndex) in item.medicalDocument"
                      :key="index + otherIndex + 'other'"
                      class="justify-content-between"
                    >
                      <label
                        class="pointer"
                        @click="getOtherImage(otherData, item.date, otherIndex)"
                      >
                        <b-badge
                          pill
                          variant="light-success"
                        >
                          Other
                        </b-badge>
                        {{ otherData }}
                      </label>
                    </div>
                  <!-- Show OPD/IPD/Document -->
                  </app-timeline-item>
                </app-timeline>
              <!-- Show patient timeline -->
              </b-col>
              <b-col
                id="ipdScroll"
                xl="9"
                cols="9"
                class="picHeight2"
              >
                <!-- Show medical record -->
                <viewerDisplayPreview
                  v-if="viewerPreviewKey !== ''"
                  :key="viewerPreviewKey"
                  :check-all-status="checkAllStatus"
                  :off-set="offSet"
                  :type="type"
                  :date="date"
                  :pic="pic"
                  :pic-group="picGroup"
                  :hn="hn"
                  :txn="txn"
                  :group="group"
                  :index="index"
                  :ipd-date="ipdDate"
                  class="container"
                />
              <!-- <general /> -->

              <!-- Show medical record -->
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <!-- Section 2 -->
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import general from '@/components/record-viewer/general.vue'
import patientDetail from '../../patientInfo/patientDetail.vue'
import viewerDisplayPreview from '../viewerPreview/viewerDisplayPreview.vue'
import manual from '../../manual/manual.vue'

export default {
  directives: { Ripple },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    patientDetail,
    viewerDisplayPreview,
    AppTimeline,
    AppTimelineItem,
    manual,
    // general,
  },
  data() {
    return {
      items: '',
      show: false,
      offSet: '',
      windowTop: 0,
      // show: false,
      pic: '',
      picGroup: [],
      hn: '',
      rid: '',
      group: '',
      type: '',
      date: '',
      data: [],
      viewerPreviewKey: '',
      txn: '',
      index: '',
      finalData: [],
      otherItems: [
        {
          otherDate: '',
          otherName: '',
          otherData: [],
        },
      ],
      checkAllStatus: false,
      disableCheckAllStatus: false,
      ipdList: [],
      ipdTimeLine: [],
      ipdDate: [],
    }
  },
  async created() {
    this.show = true
    this.hn = this.$route.params.hn
    this.rid = this.$route.params.rid
    await this.getTimeLine()
    await this.getTransferredDocuments()
    this.show = false
  },
  methods: {
    async getTransferredDocuments() {
      await this.$http({
        url: `api/hospital-information-exchange/getDocuments/${this.rid}`,
        method: 'GET',
      }).then(({ data }) => {
        this.items = Array.from(data)

        data.forEach((element, index) => {
          const myarray = []
          if (element.emr.length === 0
          && element.mr.length === 0
          && element.other.length === 0
          && element.ipd.length === 0) {
            const dataIndex = data.findIndex(i => i.date === element.date)
            data.splice(dataIndex, 1)
          }
          if (element.other.length !== 0) {
            for (let indexs = 0; indexs < element.other.length; indexs += 1) {
              if (myarray.includes(element.other[indexs].location) !== true) {
                myarray.push(element.other[indexs].location)
                this.otherItems.push({
                  otherDate: element.date,
                  otherName: element.other[indexs].location,
                  otherData: [{ nameImage: element.other[indexs].nameImage }],
                })
              } else {
                const otherItemsIndex = this.otherItems.findIndex(x => x.otherDate === element.date || x.otherName === element.other[indexs].location)
                this.otherItems[otherItemsIndex].otherData.push({
                  nameImage: element.other[indexs].nameImage,
                })
              }
            }
            // eslint-disable-next-line no-param-reassign
            data[index].medicalDocument = myarray
          }
          if (element.ipd.length !== 0) {
            this.getIpdTimeline(element.ipd)
          }
        })
      })
    },
    async getTimeLine() {
      await this.$http({
        url: '/api/Profile/HIEDetail',
        method: 'POST',
        data: {
          HN: this.hn,
        },
      }).then(({ data }) => {
        data.forEach(element => {
          if (element.medicalDocument.ipd.lenght !== 0) {
            this.ipdTimeLine.push({ date: element.date, ipd: element.medicalDocument.ipd })
          }
        })
      })
    },
    async getIpdTimeline(data) {
      const selectIpdDate = this.ipdTimeLine.find(findDate => findDate.date === data[0].date)
      this.ipdList.push({ date: data[0].date, ipdIndex: [] })
      const ipdListIndex = this.ipdList.length - 1
      data.forEach(element => {
        const selectIpdDateIndex = selectIpdDate.ipd.findIndex(findIpdIndex => findIpdIndex.imageBase64 === element.nameImage)
        this.ipdList[ipdListIndex].ipdIndex.push(selectIpdDateIndex)
      })
    },
    // async filterResult(filter) {
    //   // console.log('filter', filter)
    // },
    getMr_Image(mrData, date) {
      this.pic = mrData.nameImage
      this.type = 'MR'
      this.group = mrData.location
      this.date = date
      this.viewerPreviewKey = this.pic
      this.txn = ''
    },
    getIpd(getDate) {
      this.offSet = 0
      this.date = getDate
      this.type = 'IPD'
      this.ipdDate = this.ipdList.find(getIpdDate => getIpdDate.date === this.date)
      this.viewerPreviewKey = this.date
      this.txn = ''
    },
    getOtherImage(otherType, date, otherIndex) {
      const index = this.otherItems.findIndex(
        x => x.otherDate === date && x.otherName === otherType,
      )
      this.group = this.otherItems[index].otherName
      this.date = this.otherItems[index].otherDate
      this.picGroup = this.otherItems[index].otherData
      this.type = 'Other'
      this.viewerPreviewKey = this.date + this.group
      this.index = otherIndex
      this.txn = ''
    },
    getEmr(emrData, date) {
      this.txn = emrData.nameImage
      this.type = 'EMR'
      this.group = emrData.location
      this.date = date
      this.viewerPreviewKey = this.txn
    },
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.picHeight{
  height: 600px;
  /* overflow: auto; */
   overflow-y: scroll;
}
.picHeight::-webkit-scrollbar {
    display: none;
}
.example::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.picHeight2 {
  height: 600px;
  overflow: auto;
}
.card-padding .card-body{
  padding: 15px;
}
.px-custom-l{
  padding-left: 5px;
}
.px-custom-r{
  padding-right: 5px;
}
@media screen and (max-width: 100px) {
  .px-custom-l {
    padding-left: 14px;
    padding-right: 14px;
  }
  .px-custom-r {
    padding-left: 14px;
    padding-right: 14px;
  }
}
</style>

<style lang='scss' scoped>
</style>
